import React from "react";
import TermsAndConditions from "../components/terms/TermsAndconditions";
import AdminSection from "../components/AdminSection";

const Terms = () => {
  return (
    <div>
      <TermsAndConditions />
      <AdminSection />
    </div>
  );
};

export default Terms;
