import React from "react";
import don from "../../images/Don.jpg";
import migs from "../../images/Migs.jpeg";
import lalaz from "../../images/lalaz.jpeg";
import jay from "../../images/jay.jpeg";
import port5 from "../../images/port5.jpg";

function CompanyTeam() {
  const teamMembers = [
    {
      name: "Donald Kamthepha",
      title: "Senior Business Analyst",
      imageUrl: don,
    },
    { name: "Miguel Khan", title: "Senior Business Analyst", imageUrl: migs },
    {
      name: "Jerome Alexis",
      title: "Marketing CRM Implementation Lead",
      imageUrl: jay,
    },
    {
      name: "Quebuzar Lopez",
      title: "Project Delivery Executive",
      imageUrl: lalaz,
    },
  ];

  return (
    <div className="w-full">
      <div className="text-center py-8">
        <h1 className="text-5xl font-light text-gray-900">Meet the team</h1>
      </div>
      <div className="grid pt-10 grid-cols-1 md:grid-cols-4">
        {" "}
        {/* Removed gap-4 */}
        {teamMembers.map((member) => (
          <div key={member.name} className="relative">
            <img
              src={member.imageUrl}
              alt={member.name}
              className="w-full object-cover"
              style={{ height: "100%" }}
            />
            <div className="flex flex-col items-center absolute bottom-0 left-0 bg-black bg-opacity-75 text-white w-full p-4">
              <p className="font-light text-lg font-lora italic">{member.name}</p>
              <p className="text-sm">{member.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CompanyTeam;
