import React from "react";
import { Link } from "react-router-dom";

function CloseOutSection() {
  return (
    <div
      className="flex flex-col justify-center items-center"
      style={{
        backgroundColor: "rgb(139, 128, 225)",
        padding: "32px",
        color: "white",
        textAlign: "center",
        height: "80vh", // Use 100vh to make sure the container fills the entire viewport height
        minHeight: "300px",
      }}
    >
      <div className="flex justify-center flex-col items-center">
        <h1 className="text-black text-xl capitalize font-thin mb-8">
          WE bELIEVE
        </h1>
        <p className="text-black font-lora text-3xl mb-16  w-full md:w-1/2 italic text-center">
          {" "}
          {/* Adjust width for responsiveness */}
          Your success is our success. We work with you at every stage of the
          process to craft successful business and technology change.
        </p>
      </div>

      <div className="flex justify-center space-x-4 mt-4">
        <Link
          to="/about"
          className="bg-white text-purple-800 py-3 px-8 rounded-lg text-lg font-semibold hover:bg-gray-300 transition duration-300"
        >
          About Us
        </Link>
        <Link
          to="/contact"
          className="bg-white text-purple-800 py-3 px-8 rounded-lg text-lg font-semibold hover:bg-gray-300 transition duration-300"
        >
          Contact Us
        </Link>
      </div>
    </div>
  );
}

export default CloseOutSection;
