import React from "react";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <div className="bg-gradient-to-r from-yellow-400 via-green-400 to-orange-500 via-pink-500 to-purple-600 h-screen w-screen text-center flex flex-col justify-center items-center">
      <h1 className="text-white text-3xl md:text-5xl lg:text-6xl font-bold mb-4 italic font-lora">
        Your technology and business change implementation partner.
      </h1>
      <p className="text-white text-lg md:text-xl lg:text-2xl mb-6 font-thin font-lora">
        Make change work for your organisation with us.
      </p>
      <Link to="/projects">
        <button className="bg-white text-purple-600 py-3 px-8 rounded-lg text-lg md:text-xl lg:text-2xl font-semibold hover:bg-purple-600 hover:text-white transition duration-300">
          Our Work
        </button>
      </Link>
    </div>
  );
}

export default HeroSection;
