import { useEffect, useState } from "react";

const useFetchMessages = () => {
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMessages = async () => {
            setIsLoading(true);
            try {
                console.log("Fetching messages...");
                const response = await fetch("http://localhost:4000/api/messages"); // Ensure correct URL
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setMessages(data);
                console.log("Messages fetched successfully:", data);
            } catch (error) {
                console.error("Error fetching messages:", error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchMessages(); // Call the function to fetch messages
    }, []); // Empty dependency array to run once on mount

    return { messages, isLoading, error };
}

export default useFetchMessages;