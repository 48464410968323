import React from "react";

function CloseOutSection() {
  return (
    <div className="bg-white flex flex-col justify-center items-center">
      <div className="my-10 flex justify-center flex-col items-center">
        <h1 className="text-black text-xl capitalize font-thin mb-8 my-10 ">
          WE BELIEVE
        </h1>
        <p className="text-black font-lora text-xl mb-16 w-full md:w-1/3 italic my-10 p-8 text-center">
          Your success is our success. We work with you at every stage of the process to craft successful business and technology change.
        </p>
      </div>
    </div>
  );
}

export default CloseOutSection;
