import React from "react";
import serviceDesing from "../images/service_design.jpg";
import projectDel from "../images/project_del.jpg";
import cyber from "../images/cyber.jpg";

function ProjectSection() {
  return (
    <div>
      {[
        {
          title: "Project Management",
          projectName: "Project Delivery",
          image: projectDel,
          description: "We manage and deliver projects with a focus on achieving strategic objectives, ensuring seamless coordination across teams, and maintaining alignment with stakeholder expectations. Our approach guarantees efficient execution and successful project outcomes.",
        },
        {
          title: "Business Analysis",
          projectName: "Target Operation Model",
          image: serviceDesing,
          description:
            "We deliver platform replacement and transformation programmes, working closely with stakeholders to streamline processes and shape the Target Operating Model.",
        },
        {
          title: "Cybersecurity",
          projectName: "Cybersecurity Governance",
          image: cyber,
          description:
            "We develop response processes, playbooks, and governance frameworks to counter advanced threats. Our approach integrates risk management, business continuity, and disaster recovery strategies, ensuring business resilience against cybersecurity incidents.",
        },
      ].map((project, index) => (
        <div
          className={`project flex flex-col md:flex-row items-center bg-black md:h-50vw ${
            index % 2 !== 0 ? "md:flex-row-reverse" : ""
          }`}
        >
          <div
            className="project-img w-full md:w-1/2 md:h-50vw"
            style={{ height: "100vw", maxHeight: "50vw" }}
          >
            <img
              src={project.image}
              alt={project.projectName}
              className="object-cover w-full h-full"
            />
          </div>
          <div
            className="text-content w-full md:w-1/2 p-8 flex flex-col justify-center"
            style={{ minHeight: "50vw" }}
          >
            <h3 className="text-white text-3xl md:text-4xl lg:text-xl font-thin italic mb-4">
              {project.title}
            </h3>
            <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
              {project.projectName}
            </h1>
            <p className="text-gray-300 text-lg md:text-xl lg:text-xl mb-6">
              {project.description}
            </p>
            <div className="flex justify-start">
              {/**<button className="bg-white text-black py-2 px-6 rounded-lg text-lg font-semibold hover:bg-gray-200 hover:text-black transition duration-300">
                See Project
              </button>**/}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProjectSection;
