import React from "react";
import AdminSection from "../AdminSection";
import creative from "../../images/projects/creative.jpg"
import cyber from "../../images/cyber.jpg"
import sd_process from "../../images/port9.jpg"
import serv_design from "../../images/service_design.jpg"
import modern_desktop from "../../images/projects/modern_desktop.jpg"
import laptop from "../../images/projects/laptop.jpg"
import project_del from "../../images/projects/project_collab.jpg"
import bis_change from "../../images/projects/process_improvement.jpg"
import integration from "../../images/projects/process_map1.jpg"

const ProjectGrid = () => {
  // JSON object with project data
  const projects = [
    {
      _id: "1",
      imageUrl: creative,
      projectTitle: "Our Website",
      description: "Our pride and joy, designed and developed in house.",
    },
    {
      _id: "2",
      imageUrl: cyber,
      projectTitle: "Ransomware Response",
      description: "Ransomware governance framework.",
    },
    {
      _id: "3",
      imageUrl: sd_process,
      projectTitle: "Process Improvement",
      description: "IT Service Desk process improvement.",
    },
    {
      _id: "4",
      imageUrl: serv_design,
      projectTitle: "Service Design",
      description: "Intranet requirements discovery and service design.",
    },
    {
      _id: "5",
      imageUrl: modern_desktop,
      projectTitle: "Modern Desktop",
      description: "Apple and Windows device one touch deployment.",
    },
    {
      _id: "6",
      imageUrl: laptop,
      projectTitle: "Salesforce CRM",
      description: "Salesforce marketing CRM implementation.",
    },
    {
      _id: "7",
      imageUrl: project_del,
      projectTitle: "Project Delivery",
      description: "Project delivery and support",
    },
    {
      _id: "8",
      imageUrl: bis_change,
      projectTitle: "Business Change",
      description:
        "Business Change required to take advantage of new technology implimentations.",
    },
    {
      _id: "9",
      imageUrl: integration,
      projectTitle: "API Integration Platfrom",
      description:
        "Desinged an integration framework and implemented Mulesoft.",
    },
    // Add more project objects as needed
  ];

  return (
    <div className="bg-black min-h-screen flex flex-col items-center justify-center px-4">
      <h1 className="text-white text-4xl font-playfair mt-20 mb-10">
        Our Work
      </h1>
      <div className="w-full sm:w-11/12 md:w-4/5 lg:w-4/5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 my-20">
        {projects.map((project) => (
          <div
            key={project._id}
            className="relative group aspect-w-1 aspect-h-1"
          >
            <img
              src={project.imageUrl}
              alt={project.projectTitle}
              className="object-cover w-full h-full"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <p className="text-white text-lg font-playfair italic">
                {project.projectTitle}
              </p>
              <p className="text-center text-white text-sm font-light mt-2">
                {project.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full lg:w-full">
        <AdminSection />
      </div>
    </div>
  );
};

export default ProjectGrid;
