import React from "react";

function FeatureSection() {
  return (
    <div className="bg-white py-10 px-4 md:py-18 md:px-8">
      <h1 className="font-thin capitalize py-10 px-4 md:py-18 md:px-4">
        A FEW THINGS ABOUT US
      </h1>
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 text-center">
        <div className="feature-item py-10">
          <h3 className="text-xl font-semibold mt-6">Business Analysis</h3>
          <p className="text-md text-gray-600 mt-2 py-10">
            Business analysis is at the heart of every successful IT
            implementation and strategic business change. We focus on
            understanding your unique business needs, spotting any gaps, and
            proposing effective solutions. By leveraging our expertise, you can
            ensure that your projects delvier your business objectives, on time,
            and within budget.
          </p>
        </div>
        <div className="feature-item py-10">
          <h3 className="text-xl font-semibold mt-6">Project Delivery</h3>
          <p className="text-md text-gray-600 mt-2 py-10">
            Project management is crucial for the success of your IT initiatives
            and business transformations. We dedicate ourselves to understanding
            your specific project goals, identifying potential challenges, and
            implementing effective management strategies. Drawing on our
            experienc and expertise, we ensure your projects meet your
            objectives, stay on schedule, and remain within budget.
          </p>
        </div>
        
        <div className="feature-item py-10">
          <h3 className="text-xl font-semibold mt-6 ">Service Design</h3>
          <p className="text-md text-gray-600 mt-2 py-10">
            Service design is essential for creating seamless and effective
            customer experiences. We take the time to understand your service
            requirements, identify areas for improvement, and develop innovative
            solutions. By leveraging our skills, we help you take technology
            solutions and turn them into services that meet your customers’
            needs, driving your business forward, efficiently and
            effectively.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FeatureSection;
