import React from "react";
// Make sure to import your CSS for Playfair font and other styles

const PrivacyStatement = () => {
  return (
    <div className="bg-black min-h-screen flex flex-col items-center justify-center p-8">
      <div className="text-white w-11/12 max-w-4xl mt-20 space-y-6">
        <h1 className="text-white text-4xl text-center font-playfair ">
          Privacy Statement
        </h1>
        <p>
          <strong>Effective Date:</strong> 01 January 2024
        </p>

        <h2 className="text-2xl font-semibold">Introduction</h2>
        <p>
          Alpha Seti Ltd ("we", "us", "our") is committed to protecting your
          privacy. This Privacy Statement explains how we collect, use,
          disclose, and safeguard your information when you visit our website{" "}
          <a
            href="http://www.alpha-seti.co.uk"
            className="text-purple-500 underline"
          >
            www.alpha-seti.co.uk
          </a>{" "}
          or engage with our services. By using our website or services, you
          consent to the data practices described in this statement.
        </p>

        <h2 className="text-2xl font-semibold">Information We Collect</h2>
        <h3 className="text-xl font-semibold underline">Personal Data:</h3>
        <ul className="list-disc list-inside">
          <li>
            Contact Information: Name, email address, phone number, postal
            address.
          </li>
          <li>Identifiers: IP address, device identifiers, cookies.</li>
          <li>
            Usage Data: Information about how you use our website and services.
          </li>
        </ul>

        <h3 className="text-xl font-semibold underline">Non-Personal Data:</h3>
        <p>
          Browser type, operating system, referring URLs, and other technical
          information.
        </p>

        <h2 className="text-2xl font-semibold">How We Use Your Information</h2>
        <p>
          We use the information we collect for various purposes, including:
        </p>
        <ul className="list-disc list-inside">
          <li>To provide, operate, and maintain our website and services.</li>
          <li>To improve, personalize, and expand our website and services.</li>
          <li>
            To understand and analyze how you use our website and services.
          </li>
          <li>
            To communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes.
          </li>
          <li>To process your transactions and manage your orders.</li>
          <li>To send you emails.</li>
          <li>To find and prevent fraud.</li>
        </ul>

        <h2 className="text-2xl font-semibold">
          Legal Basis for Processing Personal Data (GDPR Compliance)
        </h2>
        <p>
          If you are from the European Economic Area (EEA), Alpha Seti Ltd's
          legal basis for collecting and using the personal information
          described in this Privacy Statement depends on the personal data we
          collect and the specific context in which we collect it. We may
          process your personal data because:
        </p>
        <ul className="list-disc list-inside">
          <li>We need to perform a contract with you.</li>
          <li>You have given us permission to do so.</li>
          <li>
            The processing is in our legitimate interests and it is not
            overridden by your rights.
          </li>
          <li>To comply with the law.</li>
        </ul>

        <h2 className="text-2xl font-semibold">Sharing Your Information</h2>
        <p>
          We do not sell or rent your personal data to third parties. We may
          share your information with:
        </p>
        <ul className="list-disc list-inside">
          <li>
            <strong>Service Providers:</strong> We may share your information
            with third-party vendors, service providers, contractors, or agents
            who perform services for us or on our behalf and require access to
            such information to do that work.
          </li>
          <li>
            <strong>Compliance with Laws:</strong> We may disclose your
            information where we are legally required to do so to comply with
            applicable law, governmental requests, judicial proceedings, court
            orders, or legal processes.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold">Data Security</h2>
        <p>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other types of misuse.
        </p>

        <h2 className="text-2xl font-semibold">Your Data Protection Rights</h2>
        <h3 className="text-xl font-semibold underline">
          Under GDPR (EEA residents):
        </h3>
        <ul className="list-disc list-inside">
          <li>
            <strong>Right to Access:</strong> You have the right to request
            copies of your personal data.
          </li>
          <li>
            <strong>Right to Rectification:</strong> You have the right to
            request that we correct any information you believe is inaccurate or
            complete information you believe is incomplete.
          </li>
          <li>
            <strong>Right to Erasure:</strong> You have the right to request
            that we erase your personal data, under certain conditions.
          </li>
          <li>
            <strong>Right to Restrict Processing:</strong> You have the right to
            request that we restrict the processing of your personal data, under
            certain conditions.
          </li>
          <li>
            <strong>Right to Object to Processing:</strong> You have the right
            to object to our processing of your personal data, under certain
            conditions.
          </li>
          <li>
            <strong>Right to Data Portability:</strong> You have the right to
            request that we transfer the data that we have collected to another
            organization, or directly to you, under certain conditions.
          </li>
        </ul>

        <h3 className="text-xl font-semibold underline">
          Under CCPA (California residents):
        </h3>
        <ul className="list-disc list-inside">
          <li>
            <strong>Right to Know:</strong> You have the right to request that
            we disclose certain information to you about our collection and use
            of your personal information over the past 12 months.
          </li>
          <li>
            <strong>Right to Delete:</strong> You have the right to request that
            we delete your personal information that we collected from you and
            retained, subject to certain exceptions.
          </li>
          <li>
            <strong>Right to Opt-Out:</strong> You have the right to direct us
            to not sell your personal information at any time.
          </li>
          <li>
            <strong>Right to Non-Discrimination:</strong> We will not
            discriminate against you for exercising any of your CCPA rights.
          </li>
        </ul>
        <p>
          To exercise any of these rights, please contact us at{" "}
          <a
            href="mailto:contact@alpha-seti.co.uk"
            className="text-purple-500 underline"
          >
            contact@alpha-seti.co.uk
          </a>
          .
        </p>

        <h2 className="text-2xl font-semibold">
          Changes to This Privacy Statement
        </h2>
        <p>
          We may update our Privacy Statement from time to time. We will notify
          you of any changes by posting the new Privacy Statement on this page.
          You are advised to review this Privacy Statement periodically for any
          changes. Changes to this Privacy Statement are effective when they are
          posted on this page.
        </p>

        <h2 className="text-2xl font-semibold">Contact Us</h2>
        <p>
          If you have any questions about this Privacy Statement, please contact
          us:
        </p>
        <ul className="list-disc list-inside">
          <li>
            <strong>By email:</strong>{" "}
            <a
              href="mailto:contact@alpha-seti.co.uk"
              className="text-purple-500 underline"
            >
              contact@alpha-seti.co.uk
            </a>
          </li>
          <li>
            <strong>By mail:</strong> 2 Campion Road, E10 5GN, London, England
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyStatement;
