import React from "react";

function HeroSection() {
  return (
    <div className="bg-gradient-to-r from-yellow-400 via-green-400 to-orange-500 via-pink-500 to-purple-600 h-screen w-screen text-center flex flex-col justify-center items-center">
      <h1 className="text-white text-xl md:text-xl lg:text-xl font-light mb-4 italic font-lora justify-start">
        WHO ARE WE
      </h1>
      <p className="text-white text-3xl md:text-5xl lg:text-5xl mb-4 italic justify-center font-lora w-3/4">
        We are a small, agile delivery partner <strong>based in London</strong>{" "}
        and operating worldwide. Our mission is to transform your strategic
        objectives into tangible benefits by driving business and technology
        change.
      </p>
    </div>
  );
}

export default HeroSection;
