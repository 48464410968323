import React from "react";
import { Link } from "react-router-dom";

function AdminSection() {
  return (
    <div className="bg-black text-white pt-20 py-6 px-10">
      <div className="lead grid grid-cols-2 gap-4 items-center">
        <div className="title-text text-center">
          <h1 className="text-xl font-semibold">Alpha-Seti Ltd</h1>
        </div>
        <div className="links flex flex-col items-start justify-center">
          <Link to="/" className="mb-3 hover:text-gray-300">
            Home
          </Link>
          <Link to="/services" className="mb-3 hover:text-gray-300">
            Services
          </Link>
          <Link to="/projects" className="mb-3 hover:text-gray-300">
            Projects
          </Link>
          <Link to="/about" className="mb-3 hover:text-gray-300">
            About
          </Link>
          <Link to="/contact" className="mb-3 hover:text-gray-300">
            Contact Us
          </Link>
        </div>
      </div>
      <hr className="my-6 border-gray-700" />
      <div className="flex flex-col sm:flex-row justify-between items-center">
        <div className="flex flex-col sm:flex-row sm:space-x-4 pb-4 sm:pb-0">
          <Link to="/privacy" className="hover:text-gray-300 mb-2 sm:mb-0">
            Privacy
          </Link>
          <Link to="/terms" className="hover:text-gray-300 mb-2 sm:mb-0">
            Terms
          </Link>
        </div>
        <p>&copy; {new Date().getFullYear()} All rights reserved.</p>
      </div>
    </div>
  );
}

export default AdminSection;
