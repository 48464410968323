import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function PageNotFound() {
  useEffect(() => {
    document.title = "404 - Page Not Found";
  }, []);

  return (
    <div className="bg-gradient-to-r from-yellow-400 via-green-400 to-orange-500 via-pink-500 to-purple-600 h-screen flex items-center justify-center">
      <div className="text-center px-4 sm:px-8 lg:px-16">
        <h1 className="text-6xl sm:text-8xl lg:text-9xl font-bold text-purple-600 font-lora">
          404
        </h1>
        <h2 className="text-xl sm:text-2xl lg:text-3xl text-white mt-4">
          Oops... seems like you got lost in space
        </h2>
        <Link to="/">
          <button className="bg-white my-10 text-purple-600 py-3 px-6 sm:px-8 lg:px-10 rounded-lg text-lg sm:text-xl lg:text-2xl font-semibold hover:bg-purple-600 hover:text-white transition duration-300">
            Back To Home
          </button>
        </Link>
      </div>
    </div>
  );
}

export default PageNotFound;
