import React, { useState } from "react";
import AdminSection from "../components/AdminSection";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Mock API call (replace with actual API logic)
    setTimeout(() => {
      setIsLoading(false);
      setMessage("If an account with this email exists, a reset link has been sent.");
    }, 2000);
  };

  return (
    <div className="bg-black flex flex-col items-center justify-center px-4 ">
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <h1 className="text-white text-4xl font-playfair mt-20 mb-10 ">
          Reset Your Password
        </h1>

        <div className="w-4/5 md:w-2/5 ">
          <form onSubmit={handlePasswordReset} className="flex flex-col">
            
            <input
              type="email"
              id="email"
              className="p-3 rounded-sm mb-4 text-black"
              placeholder="you@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              type="submit"
              className="bg-white text-purple-600 py-3 px-8 rounded-sm text-lg md:text-xl lg:text-lg font-semibold hover:bg-purple-600 hover:text-white transition duration-300"
              disabled={isLoading}
            >
              {isLoading ? "Sending..." : "Reset Password"}
            </button>
            
          </form>
          {message && (
            <p className="text-white text-sm mt-4 text-center">{message}</p>
          )}
        </div>
      </div>
        
      <AdminSection />
    </div>
  );
};

export default PasswordReset;