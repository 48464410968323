import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import FeatureSection from "../components/FeatureSection";
import ProjectSection from "../components/ProjectSection";
import CloseOutSection from "../components/CloseOutSection";
import AdminSection from "../components/AdminSection";

const Home = () => {
    return ( 
       
        <div>
            <Navbar />
            <HeroSection />
            <FeatureSection />
            <ProjectSection />
            <CloseOutSection />
            <AdminSection />
        </div>
    );
    
}
 
export default Home;