// components/CookieConsent.js

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const CookieConsent = ({ onAccept }) => {
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleAccept = () => {
    onAccept();
    setVisible(false);
  };

  const handleSettings = () => {
    navigate("/cookie-settings", { state: { from: location.pathname } });
  };

  if (!visible) return null;

  return (
    <div className="fixed bottom-0 w-full bg-black text-white text-center p-4 z-50 shadow-md flex flex-col md:flex-row justify-center items-center">
      <p className="mb-2 md:mb-0 md:mr-4">
        We use cookies to improve your experience. By continuing to use our
        site, you accept our cookie policy.
      </p>
      <div className="flex space-x-4">
        <button
          onClick={handleAccept}
          className="bg-white text-black px-4 py-2 rounded hover:bg-gray-200 transition"
        >
          Accept
        </button>
        <button
          onClick={handleSettings}
          className="bg-white text-black px-4 py-2 rounded hover:bg-gray-200 transition"
        >
          Settings
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
