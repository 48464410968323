import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="bg-black min-h-screen flex flex-col items-center justify-center p-8">
      <div className="container text-white w-11/12 max-w-4xl mt-20 space-y-6">
        <h1 className="text-4xl font-playfair text-center mb-8">
          Terms and Conditions
        </h1>
        <div className="text-lg leading-7 space-y-6">
          <p>
            Welcome to Alpha-Seti Ltd ("we," "our," "us"). These Terms and
            Conditions ("Terms") govern your use of our website located at [Your
            Website URL] ("Website") and the services we offer ("Services"). By
            accessing or using our Website and Services, you agree to be bound
            by these Terms.
          </p>

          <h2 className="text-2xl font-bold">1. Services</h2>
          <p>
            We provide IT consultancy and implementation services, including but
            not limited to business analysis, project management, CRM
            implementation, and cyber security governance.
          </p>

          <h2 className="text-2xl font-bold">2. Use of the Website</h2>
          <h3 className="text-xl font-semibold">2.1. Eligibility</h3>
          <p>
            You must be at least 18 years old to use our Website and Services.
          </p>
          <h3 className="text-xl font-semibold">2.2. User Conduct</h3>
          <p>
            You agree to use the Website only for lawful purposes and in a way
            that does not infringe the rights of, restrict, or inhibit anyone
            else's use and enjoyment of the Website.
          </p>
          <h3 className="text-xl font-semibold">2.3. Prohibited Activities</h3>
          <p>You agree not to:</p>
          <ul className="list-disc list-inside ml-4">
            <li>
              Use the Website in any way that could damage, disable, overburden,
              or impair the Website.
            </li>
            <li>
              Use any robot, spider, or other automatic device to monitor or
              copy our web pages or content.
            </li>
            <li>
              Attempt to gain unauthorized access to any part of the Website,
              other accounts, computer systems, or networks connected to the
              Website.
            </li>
          </ul>

          <h2 className="text-2xl font-bold">3. Intellectual Property</h2>
          <p>
            All content, design, text, graphics, and interfaces on the Website
            are the property of Alpha-Seti Ltd or its content suppliers and are
            protected by intellectual property laws. You may not reproduce,
            distribute, modify, or create derivative works from any content on
            the Website without our prior written consent.
          </p>

          <h2 className="text-2xl font-bold">4. Confidentiality</h2>
          <p>
            We respect your privacy and are committed to protecting it. Any
            personal information you provide to us through the Website is
            governed by our Privacy Policy.
          </p>

          <h2 className="text-2xl font-bold">5. Limitation of Liability</h2>
          <h3 className="text-xl font-semibold">5.1. Disclaimer</h3>
          <p>
            The Website and Services are provided on an "as-is" and "as
            available" basis. We make no warranties, express or implied,
            regarding the Website or the Services.
          </p>
          <h3 className="text-xl font-semibold">5.2. Exclusion of Liability</h3>
          <p>
            To the maximum extent permitted by law, we will not be liable for
            any direct, indirect, incidental, consequential, or punitive damages
            arising out of your use of the Website or Services.
          </p>

          <h2 className="text-2xl font-bold">6. Indemnification</h2>
          <p>
            You agree to indemnify, defend, and hold harmless Alpha-Seti Ltd,
            its officers, directors, employees, agents, and affiliates from any
            claims, liabilities, damages, losses, or expenses arising from your
            use of the Website or Services or your violation of these Terms.
          </p>

          <h2 className="text-2xl font-bold">7. Termination</h2>
          <p>
            We reserve the right to terminate or suspend your access to the
            Website and Services at our sole discretion, without notice, for
            conduct that we believe violates these Terms or is harmful to other
            users of the Website or us.
          </p>

          <h2 className="text-2xl font-bold">8. Governing Law</h2>
          <p>
            These Terms are governed by and construed in accordance with the
            laws of England, without regard to its conflict of law principles.
          </p>

          <h2 className="text-2xl font-bold">9. Changes to Terms</h2>
          <p>
            We may revise these Terms at any time by updating this page. Your
            continued use of the Website after any changes are made will
            constitute your acceptance of the new Terms.
          </p>

          <h2 className="text-2xl font-bold">10. Contact Information</h2>
          <p>
            If you have any questions about these Terms, please contact us at:
          </p>
          <p>
            Alpha-Seti Ltd <br />
            2 Campion Road, E10 5GN, London, England <br />
            contact@alpha-seti.co.uk
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;