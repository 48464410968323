import { useState, useEffect } from "react";
import React from "react";
import useFetchMessages from "../hooks/useFetchMessages";

const Messages = () => {

    const [messages, setMessages, Error ] = useFetchMessages()

    return ( 
        <div>
            <h3>Messages list</h3>
        </div>
     );
}
 
export default Messages;