import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import AdminSection from "../components/AdminSection";

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [captchaValue, setCaptchaValue] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaValue) {
      alert("Please complete the CAPTCHA");
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@alpha-seti\.co\.uk$/;
    if (!emailRegex.test(formData.email)) {
      alert("Please enter a valid email address (name@alpha-seti.co.uk)");
      return;
    }

    // TODO: Add login functionality here
    console.log("Login data submitted", formData);
  };

  const handleRegisterRedirect = () => {
    navigate("/register");
  };

  const handlePasswordResetRedirect = () => {
    navigate("/password-reset");
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-orange-700 to-purple-900 flex flex-col items-center justify-center">
      <div className="flex-grow flex flex-col md:flex-row overflow-hidden h-screen w-screen  items-center justify-center">
        <div className="md:w-1/2 w-full px-5 md:px-10 flex flex-col justify-center items-center overflow-hidden">
          <form onSubmit={handleSubmit} className="w-full max-w-lg space-y-6">
            <div className="w-full">
              <label className="text-white text-sm font-bold uppercase tracking-wide">
                Email
              </label>
              <input
                className="appearance-none block w-full bg-transparent text-white border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:border-purple-500"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                style={{ borderWidth: "1px" }}
              />
            </div>
            <div className="w-full">
              <label className="text-white text-sm font-bold uppercase tracking-wide">
                Password
              </label>
              <input
                className="appearance-none block w-full bg-transparent text-white border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:border-purple-500"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                style={{ borderWidth: "1px" }}
              />
            </div>
            <ReCAPTCHA
              sitekey="6LcmvOkpAAAAAMNHqz8INLrd0nOdzf0bjwA7Okcn"
              onChange={handleCaptchaChange}
            />
            <button
              type="submit"
              className="bg-purple-500 hover:bg-purple-400 focus:outline-none text-white py-3 px-6 rounded text-lg transition-colors duration-300 mt-6 w-full"
              style={{ fontSize: "1.125rem", marginBottom: "20px" }}
            >
              Login
            </button>
            <div className="w-full flex justify-between mt-4">
              <button
                type="button"
                className="text-white underline"
                onClick={handleRegisterRedirect}
              >
                Register
              </button>
              <button
                type="button"
                className="text-white underline"
                onClick={handlePasswordResetRedirect}
              >
                Forgot Password?
              </button>
            </div>
          </form>
        </div>
      </div>
      <AdminSection />
    </div>
  );
}

export default Login;