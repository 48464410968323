import React from "react";
import AdminSection from "../AdminSection";

function Experience() {
  return (
    <div>
      <div
        className="flex flex-col justify-center items-center"
        style={{
          backgroundColor: "rgb(139, 128, 225)",
          padding: "32px",
          color: "white",
          textAlign: "center",
          minHeight: "100vh", // Ensure it fills the viewport height
          minHeight: "300px",
        }}
      >
        <div className="flex justify-center flex-col items-center w-4/5 mx-auto py-20 md:py-40 mt-10">
          <h1 className="text-black text-xl capitalize font-thin md:pb-20 text-center ">
            OUR IMPLEMENTATION EXPERIENCE
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 pt-10 pb-10">
            <p className="font-lora text-black font-light text-2xl text-center">
              MS Dynamics
            </p>
            <p className="font-lora text-black font-light text-2xl text-center">
              Oracle Finance
            </p>
            <p className="font-lora text-black font-light text-2xl text-center">
              IT Help Desk Chatbot
            </p>
            <p className="font-lora text-black font-light text-2xl text-center">
              Salesforce CRM
            </p>
            <p className="font-lora text-black font-light text-2xl text-center">
              Solarwinds
            </p>
            <p className="font-lora text-black font-light text-2xl text-center">
              Apple Zero Touch Deployment - Jamf
            </p>
            <p className="font-lora text-black font-light text-2xl text-center">
              Microsoft Intune
            </p>
            <p className="font-lora text-black font-light text-2xl text-center">
              Security Orchestration Centre Governance
            </p>
          </div>
        </div>
      </div>
      <AdminSection />
    </div>
  );
}

export default Experience;
