import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [scrolled]);

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.id === "menu-overlay") {
      handleCloseMenu();
    }
  };

  return (
    <nav
      className={`fixed top-0 left-0 w-full flex items-center p-4 z-30 ${
        scrolled ? "bg-black" : "bg-transparent"
      } min-w-min`}
    >
      <div className="flex-1 md:flex-initial ">
        <Link
          to="/"
          className="text-white text-lg md:text-xl font-semibold block text-center md:text-left"
        >
          Alpha-Seti
        </Link>
      </div>
      <div className="md:hidden flex items-center">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-white text-2xl"
        >
          <FaBars />
        </button>
      </div>
      {isMenuOpen && (
        <div
          id="menu-overlay"
          className="fixed inset-0 bg-black bg-opacity-50 z-20"
          onClick={handleOverlayClick}
        >
          <div className="fixed top-0 right-0 w-3/4 max-w-sm h-full bg-black text-white p-8 flex flex-col transition-transform transform translate-x-0 z-30 side-menu">
            <div className="flex justify-end">
              <button
                onClick={handleCloseMenu}
                className="text-white text-2xl mb-4"
              >
                <FaTimes />
              </button>
            </div>
            
            <Link
              to="/"
              className="text-lg font-thin mb-4"
              onClick={handleCloseMenu}
            >
              Home
            </Link>
            <Link
              to="/services"
              className="text-lg font-thin mb-4"
              onClick={handleCloseMenu}
            >
              Services
            </Link>
            <Link
              to="/projects"
              className="text-lg font-thin mb-4"
              onClick={handleCloseMenu}
            >
              Projects
            </Link>
            <Link
              to="/about"
              className="text-lg font-thin mb-4"
              onClick={handleCloseMenu}
            >
              About
            </Link>
            <Link
              to="/contact"
              className="text-lg font-thin mb-4"
              onClick={handleCloseMenu}
            >
              Contact
            </Link>
          </div>
        </div>
      )}
      <div className="hidden md:flex flex-1 justify-end items-center space-x-8">
        
        <Link to="/" className="text-white text-lg font-thin">
          Home
        </Link>
        <Link to="/services" className="text-white text-lg font-thin">
          Services
        </Link>
        <Link to="/projects" className="text-white text-lg font-thin">
          Projects
        </Link>
        <Link to="/about" className="text-white text-lg font-thin">
          About
        </Link>
        <Link to="/contact" className="text-white text-lg font-thin">
          Contact
        </Link>
      </div>
    </nav>
  );
}

export default Navbar;
