import AdminSection from "../components/AdminSection";
import ProjectDetails from "../components/projects/ProjectDetails";
import ProjectGrid from "../components/projects/ProjectGrid";

const Projects = () => {
    return ( 
        <div>
            
            <ProjectGrid />
            
        </div>
     );
}
 
export default Projects;