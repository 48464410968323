import React from "react";
import AboutHero from "../components/about/AboutHero"
import CloseOutSection from "../components/about/CloseOutSection";
import CompanyTeam from "../components/about/CompanyTeam";
import AdminSection from "../components/AdminSection";
import Experience from "../components/about/Experience";


const About = () => {
    return (
      <div>
        <AboutHero />
        <CloseOutSection />
        <CompanyTeam />
        <Experience />
        
      </div>
    );
}
 
export default About;