// pages/CookieSettings.js

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CookieSettings = () => {
  const [preferences, setPreferences] = useState({
    essential: true,
    analytics: false,
    marketing: false,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || "/";

  useEffect(() => {
    const savedPreferences = JSON.parse(
      sessionStorage.getItem("cookiePreferences")
    );
    if (savedPreferences) {
      setPreferences(savedPreferences);
    }
  }, []);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setPreferences((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSave = () => {
    sessionStorage.setItem("cookiePreferences", JSON.stringify(preferences));
    sessionStorage.setItem("cookiesAccepted", "true");
    toast.success("Preferences saved");
    setTimeout(() => {
      navigate(from);
    }, 500);
  };

  const handleCancel = () => {
    navigate(from);
  };

  return (
    <div className="cookie-settings flex flex-col items-center p-6 bg-gray-50 min-h-screen">
      <ToastContainer />
      <h2 className="text-2xl font-lora mt-20 mb-6">Cookie Preferences</h2>
      <div className="flex flex-col items-center space-y-4">
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            name="essential"
            checked={preferences.essential}
            disabled
            className="form-checkbox"
          />
          <span>Essential Cookies (Always enabled)</span>
        </label>
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            name="analytics"
            checked={preferences.analytics}
            onChange={handleChange}
            className="form-checkbox"
          />
          <span>Analytics Cookies</span>
        </label>
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            name="marketing"
            checked={preferences.marketing}
            onChange={handleChange}
            className="form-checkbox"
          />
          <span>Marketing Cookies</span>
        </label>
      </div>
      <div className="flex space-x-4 mt-6">
        <button
          onClick={handleSave}
          className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 transition"
        >
          Save Preferences
        </button>
        <button
          onClick={handleCancel}
          className="bg-gray-500 text-white px-6 py-2 rounded hover:bg-gray-600 transition"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CookieSettings;
