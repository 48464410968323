import React, { useState } from "react";
import Admin from "../components/AdminSection"
import AdminSection from "../components/AdminSection";

const Registration = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleRegistration = async (e) => {
    e.preventDefault();

    const emailPattern = /^[a-zA-Z0-9._%+-]+@alpha-seti\.co\.uk$/;
    if (!emailPattern.test(email)) {
      setMessage("Please use a valid email address.");
      return;
    }

    setIsLoading(true);

    // Mock API call (replace with actual API logic)
    setTimeout(() => {
      setIsLoading(false);
      setMessage("Registration successful. Please check your email for further instructions.");
    }, 2000);
  };

  return (
    <div className="bg-black flex flex-col items-center justify-center px-4 ">
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <h1 className="text-white text-4xl font-playfair mt-20 mb-10">
          Account Registration
        </h1>
        <div className="w-4/5 md:w-2/5 ">
          <form onSubmit={handleRegistration} className="flex flex-col">
        
            <input
              type="email"
              id="email"
              className="p-3 rounded-sm mb-4 text-black"
              placeholder="name@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            
            <input
              type="password"
              id="password"
              className="p-3 rounded-sm mb-4 text-black"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="submit"
              className="bg-white text-purple-600 py-3 px-8 rounded-sm text-lg md:text-xl lg:text-lg font-semibold hover:bg-purple-600 hover:text-white transition duration-300"
              disabled={isLoading}
            >
              {isLoading ? "Registering..." : "Register"}
            </button>
          </form>
          {message && (
            <p className="text-white text-lg mt-4 text-center">{message}</p>
          )}
        </div>

      </div>
      
      <AdminSection />
    </div>
  );
};

export default Registration;