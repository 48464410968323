import React from "react";
import PrivacyStatement from "../components/privacy/PrivacyStatement";
import AdminSection from "../components/AdminSection";
// Make sure to import your CSS for Playfair font and other styles

const Privacy= () => {
  return (
    <div>
      <PrivacyStatement />
      <AdminSection />
    </div>
  );
};

export default Privacy;
